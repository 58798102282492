import {AUTH_COOKIE_NAME} from '../conf/app.js';

export default defineNuxtPlugin(async () => {

  const session = useSessionStore();
  const users = useUsersStore();
  const cookie = useCookie(AUTH_COOKIE_NAME);
  const preferences = usePreferencesStore();
  const notifications = useNotificationStore();

  if (cookie.value) {
    await nextTick();
    await session.getCurrentUser();
  }

  if (process.client) {
    let timer;
    const stopNotificationPolling = () => clearInterval(timer);
    const startNotificationPolling = () => {
      stopNotificationPolling();
      notifications.getNewCount();
      timer = setInterval(notifications.getNewCount, useRuntimeConfig().public.notificationCountRefreshInterval);
    }


    session.getUserStoreInfo();

    if (session.isAuthenticated) {
      await preferences.getCurrentUserPreferences();
      session.getDeleteBinSummary();
      users.getManagedConnections();

      setTimeout(startNotificationPolling, 300);
    }

    watch(() => session.currentUser, async (newVal, oldVal) => {
      const didUserChange = newVal?.id !== oldVal?.id;

      //need this to wait for current user change for who the fuck knows why
      await nextTick();

      if (didUserChange) {
        session.getUserStoreInfo();

        if (session.isAuthenticated) {
          startNotificationPolling()
        } else {
          stopNotificationPolling();
        }
      }

      if (session.isAuthenticated) {
        preferences.getCurrentUserPreferences();
        session.getDeleteBinSummary();
        users.getManagedConnections();
      }

    })
  }

});
