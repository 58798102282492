import {acceptHMRUpdate, defineStore} from 'pinia';

export const useUsersStore = defineStore('users', {

  state: () => ({
    connections: null,
    connectionsHasMore: false,
    connectionsCursor: null, //note: has to be here for ssr hydration (instead of a declaration up top)

    managedConnections: null,

    pending: null,
    sent: null,

    searchResults: null,
    searchResultsHasMore: false,
    searchResultsCursor: null,
    searchTerm: '',

    currentProfileUser: null
  }),

  actions: {
    async getConnections({nextPage} = {}) {
      if (!nextPage) {
        this.connectionsCursor = null;
        this.connectionsHasMore = false;
      }

      const data = await useUserApi().fetchConnections({cursor: this.connectionsCursor});

      this.connections = nextPage ? this.connections : [];
      this.connections.push(...data.friendships);
      this.connectionsHasMore = data.friendships.length === data.per_page;
      this.connectionsCursor = data.next_cursor;

      return this.connections;
    },

    async getManagedConnections() {
      const connections = await useUserApi().fetchManagedConnections();
      this.managedConnections = connections || [];
      return this.managedConnections;
    },

    clearConnections() {
      this.connections = null;
      this.connectionsHasMore = false;
      this.connectionsCursor = null;
    },


    async getPending() {
      this.pending = await useUserApi().fetchPending();
      return this.pending;
    },
    clearPending() {
      this.pending = null;
    },

    async getSent() {
      this.sent = await useUserApi().fetchSent();
      return this.sent;
    },
    clearSent() {
      this.sent = null;
    },

    async search({term, isPublic = false, nextPage}) {

      if (!nextPage || this.searchTerm !== term) {
        this.clearSearchResults();
      }

      this.searchTerm = term;

      if (!this.searchTerm) {
        return this.clearSearchResults();
      }

      const data = await useUserApi().search({
        cursor: this.searchResultsCursor,
        term,
        isPublic
      });

      this.searchResults = this.searchResults || [];
      this.searchResults.push(...data.users);
      this.searchResultsHasMore = data.users.length === data.per_page;
      this.searchResultsCursor = data.next_cursor;

      return this.searchResults;
    },
    clearSearchResults() {
      this.searchResults = null;
      this.searchResultsHasMore = false;
      this.searchResultsCursor = null;
    },

    async getUserProfile({userIdOrSlug}) {
      this.currentProfileUser = await useUserApi().getUser({id: userIdOrSlug});
      return this.currentProfileUser;
    },

    async refreshConnection({userIdOrSlug}) {
      const updated = await useUserApi().getUser({id: userIdOrSlug});

      if (this.currentProfileUser) {
        this.currentProfileUser = updated;
      }

      if (this.connections) {
        const index = this.connections.findIndex(item => item.id === updated.id);
        if (index > -1) {
          this.connections[index] = updated;
        }
      }
    },

    async cancelSentRequest({user}) {
      await useUserApi().deleteConnection({
        friendshipId: user.friendship.id
      });

      const updateIndex = this.sent?.findIndex(item => item.friendship.id === user.friendship.id);
      if (updateIndex > -1) {
        this.sent?.splice(updateIndex, 1);
      }

      const toUpdate = this.searchResults?.find(u => user.id === u.id)
      if(toUpdate) {
        toUpdate.friendship.status = FRIEND_STATUS.none;
        toUpdate.friendship.id = null;
      }

      if (this.currentProfileUser?.id === user.id) {
        this.getUserProfile({userIdOrSlug: this.currentProfileUser.id})
      }

      return true;
    },


    async addConnection({user}) {
      const newUser = await useUserApi().createConnectionRequest({user});

      const toUpdate = this.searchResults?.find(u => user.id === u.id)
      if(toUpdate) {
        toUpdate.friendship.status = FRIEND_STATUS.sent;
        toUpdate.friendship.id = newUser.friendship.id;
      }

      if (this.currentProfileUser?.id === user.id) {
        this.getUserProfile({userIdOrSlug: this.currentProfileUser.id})
      }

      return newUser;
    },


    async removeConnection({user}) {
      await useUserApi().deleteConnection({
        friendshipId: user.friendship.id
      });

      const updateIndex = this.connections?.findIndex(item => item.friendship.id === user.friendship.id);
      if (updateIndex > -1) {
        this.connections?.splice(updateIndex, 1);
      }

      if (this.currentProfileUser?.id === user.id) {
        this.getUserProfile({userIdOrSlug: this.currentProfileUser.id})
      }

      return true;
    },


    async acceptPendingRequest({user}) {
      await useUserApi().acceptConnectionRequest({
        friendshipId: user.friendship.id
      });

      //remove item from pending list
      const updateIndex = this.pending?.findIndex(item => item.friendship.id === user.friendship.id);
      if (updateIndex > -1) {
        this.pending?.splice(updateIndex, 1);
      }

      const toUpdate = this.searchResults?.find(u => user.id === u.id)
      if(toUpdate) {
        toUpdate.friendship.status = FRIEND_STATUS.completed;
      }

      //refresh connections list
      if (this.connections) {
        await this.getConnections();
      }

      //refresh profile
      if (this.currentProfileUser?.id === user.id) {
        this.getUserProfile({userIdOrSlug: this.currentProfileUser.id})
      }
    },

    async ignorePendingRequest({user}) {
      await useUserApi().deleteConnection({
        friendshipId: user.friendship.id
      });

      const updateIndex = this.pending?.findIndex(item => item.friendship.id === user.friendship.id);

      if (updateIndex > -1) {
        this.pending?.splice(updateIndex, 1);
      }

      const toUpdate = this.searchResults?.find(u => user.id === u.id)
      if(toUpdate) {
        toUpdate.friendship.status = FRIEND_STATUS.none;
        toUpdate.friendship.id = null;
      }

      if (this.currentProfileUser?.id === user.id) {
        this.getUserProfile({userIdOrSlug: this.currentProfileUser.id})
      }

      return true;
    },

    async toggleManager({user, isManager}) {
      await useUserApi().updateConnection({user, manager: isManager});

      this.connections?.forEach(item => {
        if (item.id === user.id) {
          item.friendship.managed_by = isManager;
        }
      });

      if (this.currentProfileUser?.id === user.id) {
        this.getUserProfile({userIdOrSlug: this.currentProfileUser.id})
      }

      return true;
    }

  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsersStore, import.meta.hot));
}
