export const CURRENT_USER_PREFERENCES = {

  //settings
  exifDownloadsEnabled: 'exifDownloadsEnabled',
  exifDownloadsPrompt: 'exifDownloadsPrompt',
  marsLandingPath: 'landing_path',
  videoDeleteOriginal: 'video_delete_original',

  //onboarding states
  onbPeopleDismissed: 'onb_people_dismissed',
  onbDeclutterDismissed: 'onb_declutter_dismissed',
};
