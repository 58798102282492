import { default as index8sdl9A9GDZMeta } from "/tmp/build_f2ed9692/pages/connections/index.vue?macro=true";
import { default as indexUD7ZvzcfNZMeta } from "/tmp/build_f2ed9692/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_93N9G58q2reKMeta } from "/tmp/build_f2ed9692/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_93SPHxb3cabQMeta } from "/tmp/build_f2ed9692/pages/gallery/[file_id].vue?macro=true";
import { default as galleryAYDV7bWExjMeta } from "/tmp/build_f2ed9692/pages/gallery.vue?macro=true";
import { default as indexni62cF6VBJMeta } from "/tmp/build_f2ed9692/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93q2xhqAjKxnMeta } from "/tmp/build_f2ed9692/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45in1vU7oUHMUtMeta } from "/tmp/build_f2ed9692/pages/log-in.vue?macro=true";
import { default as log_45outY91tFPZqYiMeta } from "/tmp/build_f2ed9692/pages/log-out.vue?macro=true";
import { default as indexIfK8cw99k8Meta } from "/tmp/build_f2ed9692/pages/notifications/index.vue?macro=true";
import { default as projectsQ8XcTy0ZaIMeta } from "/tmp/build_f2ed9692/pages/projects.vue?macro=true";
import { default as _91file_id_932FbaXY2adGMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93FTAoN5mJxyMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexdss1v37W96Meta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedNWHqTaVJHYMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_93SBVpX2bHpsMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_938mn2PE644GMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93dMLsxAqtt8Meta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexN9CBdLdLbMMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93MMCmGkkdMlMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_933wT74tCA4GMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexLHBIkfiEbAMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93pQMsQvpb3EMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsToOE3rLZuMMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93FOnxIX2JhkMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_939Lr3wqTAiWMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexQ7YdgpYJQOMeta } from "/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomeDgSoode4avMeta } from "/tmp/build_f2ed9692/pages/welcome.vue?macro=true";
import { default as component_45stub3psuv4BDACMeta } from "/tmp/build_f2ed9692/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub3psuv4BDAC } from "/tmp/build_f2ed9692/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: index8sdl9A9GDZMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexUD7ZvzcfNZMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_f2ed9692/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryAYDV7bWExjMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_f2ed9692/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexni62cF6VBJMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_f2ed9692/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_f2ed9692/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_f2ed9692/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexIfK8cw99k8Meta || {},
    component: () => import("/tmp/build_f2ed9692/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsQ8XcTy0ZaIMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: indexdss1v37W96Meta || {},
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedNWHqTaVJHYMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexLHBIkfiEbAMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsToOE3rLZuMMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexQ7YdgpYJQOMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeDgSoode4avMeta || {},
    component: () => import("/tmp/build_f2ed9692/pages/welcome.vue")
  },
  {
    name: component_45stub3psuv4BDACMeta?.name,
    path: "/",
    component: component_45stub3psuv4BDAC
  },
  {
    name: component_45stub3psuv4BDACMeta?.name,
    path: "/library",
    component: component_45stub3psuv4BDAC
  },
  {
    name: component_45stub3psuv4BDACMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stub3psuv4BDAC
  }
]