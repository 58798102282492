import {CoreModalConfirm} from '#components';

const modals = ref([]);

class Modal {
  constructor({
    id = Date.now(),
    size = 'md',
    bgClass = 'bg-white',
    backdropClass = 'bg-black/50',
    overflowClass = 'overflow-hidden',
    component,
    props,
    eventHandlers,
    showClose = true,
    clickToClose = true,
    escToClose = true,
    onClose = () => {},
    onOpen = () => {}
  } = {}) {
    this.id = id;
    this.size = size;
    this.bgClass = bgClass;
    this.backdropClass = backdropClass;
    this.overflowClass = overflowClass;
    this.showClose = showClose;
    this.clickToClose = clickToClose;
    this.escToClose = escToClose;
    this.component = shallowRef(component);
    this.props = props;
    this.onClose = onClose;
    this.onOpen = onOpen;
    this.eventHandlers = Object.entries(eventHandlers || {})
      .reduce((boundHandlers, [eventName, handler]) => {
        if (typeof handler === 'function') {
          boundHandlers[eventName] = handler.bind(this);
        }
        return boundHandlers;
      }, {});
    this.isOpen = false;

    this.isFullScreen = (size === 'fullscreen');

    this.sizeClass = computed(() => {
      switch (size) {
        case 'sm':
          return 'w-[400px]';
        case 'md':
          return 'w-[600px]';
        case 'lg':
          return 'w-[900px]';
        case 'xl':
          return 'w-[1200px]';
        case '2xl':
          return 'w-[1600px]';
        case 'fullscreen':
          return 'w-screen h-dvh md:h-screen';
      }
    });
  }

  async open() {
    modals.value.push(this);
    await nextTick();
    this.isOpen = true;
    this.onOpen();
    return this;
  }

  async close() {
    this.isOpen = false;
    await nextTick();
    await waitFor(250); //this is a magic number, whatev
    modals.value = modals.value.filter(item => item.id !== this.id);
    this.onClose();
  }

}

export function useFModal(args) {
   return reactive(new Modal(args));
}

export function useConfirmModal({heading, confirmMethod, color = 'red', message, onConfirm}) {
  return reactive(new Modal({
    size: 'sm',
    showClose: false,
    component: CoreModalConfirm,
    props: {
      color,
      heading,
      confirmMethod,
      message
    },
    eventHandlers: {
      onConfirm
    }
  }));
}

export function useFModals() {

  function clear() {
    modals.value.length = 0;
  }

  function closeTopModal() {
    modals.value.pop();
  }

  function getTopModal() {
    return modals.value[modals.value.length - 1];
  }

  return {
    modals,
    clear,
    closeTopModal,
    getTopModal,
    areModalsOpen: computed(() => modals.value.length > 0)
  };
}
