const apiPaths = {
  users: '/users',
  user: '/users/:id',
  friendships: '/users/:userId/friendships',                    //get, post
  friendship: '/users/:userId/friendships/:id',                 //put, delete,
  acceptFriendRequest: '/users/:userId/friendships/:id/accept', //put
  pendingFriendRequests: '/users/:userId/friendships/pending',
  sentFriendRequests: '/users/:userId/friendships/sent',
  publicSearch: '/users/public_search',

  emailInvite: '/email_invitations'
};

export function useUserApi() {

  const userId = useSessionStore().currentUserId;

  async function fetchConnections({cursor}) {
    const data = await useMarsApiV3().fetch(
      apiPaths.friendships,
      {
        params: {
          route: {userId},
          query: {cursor}
        }
      }
    );

    data.friendships = data.friendships.map(mapFriendshipToUser);

    return data;
  }

  async function fetchManagedConnections() {
    //todo: better backend support for this
    const data = await fetchAllConnections();
    return data.filter(f => f.friendship.manager_of);
  }

  async function fetchAllConnections() {
    return await fetchAllRecords('friendships', cursor => fetchConnections({cursor}));
  }

  async function fetchPending() {
    const pending = await fetchAllRecords('friendships', cursor => useMarsApiV3().fetch(
      apiPaths.pendingFriendRequests,
      {
        params: {
          route: {userId},
          query: {cursor}
        }
      }
    ));

    return pending.map(mapFriendshipToUser);
  }

  async function fetchSent() {
    const sent = await fetchAllRecords('friendships', cursor => useMarsApiV3().fetch(
      apiPaths.sentFriendRequests,
      {
        params: {
          route: {userId},
          query: {cursor}
        }
      }
    ));

    return sent.map(mapFriendshipToUser);
  }

  async function search({cursor, term, isPublic}) {
    const data = await useMarsApiV3().fetch(
      isPublic ? apiPaths.publicSearch : apiPaths.users,
      {
        params: {
          query: {
            search: term,
            cursor
          }
        }
      }
    );

    data.users = data.users.map(userMapper);

    return data;
  }

  async function getUser({id}) {
    const data = await useMarsApiV3().fetch(
      apiPaths.user,
      {
        params: {
          route: {id}
        }
      }
    );

    return userMapper(data.user)
  }


  async function createConnectionRequest({user}) {
    const data = await useMarsApiV3().fetch(
      apiPaths.friendships,
      {
        method: 'POST',
        body: {friend_id: user.id},
        params: {
          route: {userId}
        }
      }
    );

    return mapFriendshipToUser(data.friendship);
  }

  async function acceptConnectionRequest({friendshipId}) {
    return await useMarsApiV3().fetch(
      apiPaths.acceptFriendRequest,
      {
        method: 'PUT',
        params: {
          route: {
            userId,
            id: friendshipId
          }
        }
      }
    );
  }

  async function updateConnection({user, manager}) {
    const data = await useMarsApiV3().fetch(
      apiPaths.friendship,
      {
        method: 'PUT',
        body: {manager},
        params: {
          route: {
            userId,
            id: user.friendship.id
          }
        }
      }
    );
    return mapFriendshipToUser(data.friendship);

  }

  async function deleteConnection({friendshipId}) {
    return await useMarsApiV3().fetch(
      apiPaths.friendship,
      {
        method: 'DELETE',
        params: {
          route: {
            userId,
            id: friendshipId
          }
        }
      }
    );
  }

  async function inviteByEmail({to_email, message}) {
    return await useMarsApiV3().fetch(
      apiPaths.emailInvite,
      {
        method: 'POST',
        body: {to_email, message}
      }
    );
  }

  return {
    fetchConnections,
    fetchAllConnections,
    fetchManagedConnections,
    fetchPending,
    fetchSent,
    search,
    getUser,
    createConnectionRequest,
    acceptConnectionRequest,
    updateConnection,
    deleteConnection,
    inviteByEmail
  };
}
